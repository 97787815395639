<template>
  <div class="me-2 position-relative image-cropper">
    <div class="image-input image-input-outline" data-kt-image-input="true">
      <div class="image-input-wrapper w-125px h-125px" ref="bank-img" :style="`background-image: url(${blankImage})`"></div>

      <label
          v-if="!disabled"
          class="image-cropper--btn image-cropper__edit"
          data-kt-image-input-action="change"
          data-bs-toggle="tooltip"
          title="Change avatar"
          @change="uploadImage"
      >
        <i class="bi bi-pencil-fill fs-7"></i>

        <input type="file" name="avatar" :accept="acceptedFiles" />
        <input type="hidden" name="avatar_remove" />
      </label>

      <span class="image-cropper--btn image-cropper__remove" @click="clear" v-if="!disabled">
        <i class="bi bi-x fs-2"></i>
      </span>
    </div>

    <div class="form-text" v-if="!disabled">Allowed file types: {{ allowedFileTypes.join(', ') }}.</div>

    <div class="loading-overlay" v-if="loading"></div>
  </div>


  <el-dialog
    custom-class="picture-cropper-modal"
    append-to-body
    center
    v-model="dialogVisible"
    width="800px"
  >
    <template #title>
      <h4>{{ popupTitle }}</h4>
      <p>{{ popupDescription }}</p>
    </template>
    <VuePictureCropper
      :boxStyle="{
        width: '100%',
        height: '100%',
        backgroundColor: '#f8f8f8',
        margin: 'auto',
      }"
      :img="pic"
      :options="{
        viewMode: 1,
        dragMode: 'crop',
        aspectRatio: 16 / 16,
      }"
    />

    <div class="d-flex justify-content-end">
      <button class="main-btn-outline btn mt-6" @click.prevent="dialogVisible = false">
        {{ $t('Cancel') }}
      </button>
      <button class="main-btn btn mt-6" @click.prevent="getResult">
        {{ $t('Save') }}
      </button>
    </div>
  </el-dialog>
</template>
<script>
import {Constants} from "@/core/config/constants";
import VuePictureCropper, { cropper } from 'vue-picture-cropper'
import {BlankImageTypesEnum} from "@/store/enums/BlankImageTypesEnum";

export default {
  name: "FileUploadWithCropper",
  data() {
    return {
      images: null,
      errors: [],
      dialogVisible: false,
      pic: '',
      result: {
        dataURL: '',
        blobURL: '',
      },
      blankImageTypes: {
        [BlankImageTypesEnum.AVATAR]: Constants.USER_TMP_LOGO,
        [BlankImageTypesEnum.BANK]: Constants.BANK_TMP_LOGO,
        [BlankImageTypesEnum.COMPANY]: Constants.BUSINESS_TMP_LOGO
      }
    };
  },
  components: {
    VuePictureCropper
  },
  props: {
    imageUrl: String,
    blankImageType: {
      type: String,
      default: BlankImageTypesEnum.AVATAR
    },
    loading: {
      type: Boolean,
      default: false
    },
    acceptedSize: {
      type: Number,
      default: 1024
    },
    allowedFileTypes: {
      type: Array,
      default: ['png', 'jpg', 'jpeg', 'svg', 'JPG']
    },
    popupTitle: String,
    popupDescription: String,
      disabled: {
        type: Boolean,
          default: false,
      }
  },
  emits: ["getImages", "getErrors"],
  computed: {
    blankImage() {
      if (this.imageUrl) {
        return this.imageUrl
      } else {
        return this.blankImageTypes[this.blankImageType];
      }
    },
    acceptedFiles() {
      return this.allowedFileTypes.map(item => '.' + item).join(', ')
    }
  },
  methods: {
    uploadImage(e) {
      let files = e.target.files;
      if (files && files[0]) {
        this.images = null;
        this.validateAndAddFile(files[files.length - 1]);
      }
    },
    validateAndAddFile(file) {
      if (file.size > this.acceptedSize * 1024) {
        this.errors = [
          this.$t('File size exceeds maximum limit', { limit: this.acceptedSize / 1024 })
        ]
        this.$emit("getErrors", this.errors);
        return;
      } else if (!this.allowedFileTypes.includes(this.extension(file.name))) {
        this.errors = [
          this.$t('You have uploaded an invalid image file type')
        ]
        this.$emit("getErrors", this.errors);
        return;
      } else {
        this.errors = []
        this.images = file;
      }

      if (this.images) {
        this.constructPreview();
      } else {
        this.errors = [];
      }
      this.$emit("getErrors", this.errors);
    },
    extension(filename) {
      var r = /.+\.(.+)$/.exec(filename);
      return r ? r[1] : null;
    },
    constructPreview() {

      this.pic = ''
      this.result.dataURL = ''
      this.result.blobURL = ''

      let reader = new FileReader();
      reader.onload = (e) => {
        this.pic = String(reader.result)
        this.dialogVisible = true;
      };

      reader.readAsDataURL(this.images);
    },

    clear() {
      if (cropper) {
        cropper.clear();
      }
      this.pic = '';
      this.setImageUrl(Constants.BUSINESS_TMP_LOGO);
      this.$emit("getImages", []);
    },

    async getResult() {
      if (!cropper) return
      const base64 = cropper.getDataURL()
      const file = await cropper.getFile();

      this.setImageUrl(base64);

      this.$emit("getImages", [file]);
      this.dialogVisible = false
    },

    setImageUrl(url) {
      let element = this.$refs["bank-img"]
      element.style.backgroundImage = "url(" + url + ")";
    }
  }
};
</script>
<style lang="scss">
.image-cropper {
  .loading-overlay {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff8a;
    z-index: 9;
  }
  &--btn {
    position: absolute;
    right: -9px !important;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    left: revert !important;
    transform: revert !important;
  }
  &__edit {
    top: -6px !important;
    background: #FFFFFF;
    border: 1px solid #435BF4;
    i {
      color: #435BF4;
    }
  }
  &__remove {
    bottom: -6px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
  }
}
.picture-cropper-modal {
  text-align: left;
  .el-dialog__header {
    padding: 30px 40px 0 40px;
    h4 {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
      color: #000000;
      margin-bottom: 8px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 178.52%;
      color: #434343;
      opacity: 0.6;
    }
  }
  .el-dialog__body {
    padding: 0 40px 30px 40px;
  }
  .main-btn {
    min-width: 107px;
  }
  .main-btn-outline {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #969696 !important;
  }
}

@media (max-width: 810px) {
  .picture-cropper-modal {
    --el-dialog-width: 98% !important;
  }
}
</style>
